export const BEGIN_API_CALL = "BEGIN_API_CALL"
export const BEGIN_LOAD_MORE_API_CALL = "BEGIN_LOAD_MORE_API_CALL"
export const API_CALL_ERROR = "API_CALL_ERROR"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const LOAD_ATHLETE_ACTIVITIES_SUCCESS = "LOAD_ATHLETE_ACTIVITIES_SUCCESS"
export const LOAD_FILTERED_ACTIVITIES_SUCCESS = "LOAD_FILTERED_ACTIVITIES_SUCCESS"
export const LOAD_MORE_ATHLETE_ACTIVITIES_SUCCESS = "LOAD_MORE_ATHLETE_ACTIVITIES_SUCCESS"
export const NEXT_PAGE = "NEXT_PAGE"
export const RESET_PAGE_NUMBER = "RESET_PAGE_NUMBER"
export const LOAD_ATHLETE_DATA_SUCCESS = "LOAD_ATHLETE_DATA_SUCCESS"
export const LOAD_ACTIVITY_STREAM_SUCCESS = "LOAD_ACTIVITY_STREAM_SUCCESS"
export const LOAD_CURRENT_ACTIVITY_SUCCESS = "LOAD_CURRENT_ACTIVITY_SUCCESS"
export const AUTHORISE_USER_SUCCESS = "AUTHORISE_USER_SUCCESS"
export const LOGOUT_USER = "LOGOUT_USER"
export const OPEN_SIDEBAR = "OPEN_SIDEBAR"
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR"
export const SET_UNIT_IMPERIAL = "SET_UNIT_IMPERIAL"
export const SET_UNIT_METRIC = "SET_UNIT_METRIC"
export const LOAD_USER_DATA_SUCCESS = "LOAD_USER_DATA_SUCCESS"
export const STORE_STRAVA_AUTH_SUCCESS = "STORE_STRAVA_AUTH_SUCCESS"
export const REFRESH_STRAVA_TOKEN = "REFRESH_STRAVA_TOKEN"
export const COPY_STRAVA_ACTVITIES = "COPY_STRAVA_ACTVITIES"
export const LOAD_SESSIONS_SUCCESS = "LOAD_SESSIONS_SUCCESS"
export const NO_MORE_ACTIVITIES = "NO_MORE_ACTIVITIES"
export const UPDATE_FIRESTORE_SESSIONS = "UPDATE_FIRESTORE_SESSIONS"
export const RESET_ATHLETE_ACTIVITIES_SIZE = "RESET_ATHLETE_ACTIVITIES_SIZE"
export const MODIFY_ACTIVITY_TYPE = "MODIFY_ACTIVITY_TYPE"
export const MODIFY_CURRENT_ACTIVITY_TYPE = "MODIFY_CURRENT_ACTIVITY_TYPE"
export const UPDATE_SELECTED_DATE = "UPDATE_SELECTED_DATE"
export const RESET_SELECTED_DATE = "RESET_SELECTED_DATE"
export const REMOVE_SESSION = "REMOVE_SESSION"
export const ADD_SESSION = "ADD_SESSION"
export const DELETE_ACTIVITY = "DELETE_ACTIVITY"
export const DELETE_CURRENT_ACTIVITY = "DELETE_CURRENT_ACTIVITY"
export const UPDATE_ACTIVITY_NAME = "UPDATE_ACTIVITY_NAME"
export const UPDATE_CURRENT_NAME = "UPDATE_CURRENT_NAME"
